/* Header */
.header {
  position: relative;
  height: 110vh;
  overflow: hidden;
  background-color: rgb(234, 247, 244);
}
.heading-details {
  z-index: 99;
  position: absolute;
  top: 40%;
  left: 30%;
}
.heading-details h2 {
  color: #003366;
  font-weight: 600;
  font-size: 2.2rem;
  letter-spacing: 2px;
  word-spacing: 3px;
}
.heading-details h3 {
  color: rgb(112, 119, 138);
}
.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.1;
    pointer-events: none;
  }
  .video-background iframe {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
.header-img {
  position: absolute;
  right: -100px;
  width: 50vw;
  top: 0;
}

.header-filter {
  position: relative;
  width: 100%;
  z-index: 2;
  left: 0%;
  top: 20vh;
}
.search-bar {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.tabs {
  display: flex;
}
.sell-tab {
  background-color: #003366;
  color: white !important;
}
.rent-tab {
  background-color: white;
  color: white;
}
.tab.active {
  color: #003366;
}


.search-input > input:focus{
  border: 0px !important;
}
.keyword-input {
  outline: none;
  padding: 16px;
  width: 300px;
  border-radius: 4px;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-button:hover + .dropdown-content,
.dropdown-content:hover {
  display: block;
}
.filter-icon {
  font-size: 20px;
  background: none;
  border: none;
  cursor: pointer;
}
.search-button {
  padding: 10px;
  background-color: #003366;
  color: white;
  border: none;
  cursor: pointer;
}
.checkboxes {
  display: flex;
  gap: 10px;
}
.category-select{
  width: 180px;
}
.category-select .select-cat {
  border:0px !important;
  outline: none;
  padding: 8px;
  height: 56px;
  border-radius: 0;
  box-shadow: none;
}
.select-cat > button:hover{
  background-color: #fff !important;
  color: gray !important;
}
.select-cat > button.show {
  background-color: #fff !important;
  color: gray !important;
}
.select-cat > button{
  border:0px !important;

}
.select-cat > div{
  margin-top: 20px;
  width: 200px;
  border-radius: 2px;
  border:0px !important;
}
/* Main Search */
.main-search {
  position: absolute;
  left: 15%;
}

.nav-tabs .nav-link{
  background-color: white;
  color: #003366;
}
.nav-tabs .nav-link.active{
  background-color:#003366 !important;
  color: white !important;
}


.main-search .search-btns button {
  padding: 8px 20px;
  color: #fff;
  border: none;
  outline: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: 100;
}
.search-input input {
  border: 0px !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #ccc;
}
.search-details {
  display: flex;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e1e4e8;
  box-shadow: 0 8px 12px 0 rgba(36, 104, 147, .04);
}

.filter-icon i{
  padding: 15px 20px;
  background-color: #fff;
  margin-right: 2px;
}

.search-btn button{
  background-color: #003366;
  color: #fff;
  font-size: 0.8rem;
  border: none;
  outline: none;
  padding: 18.5px 20px;
  margin-left: 0px;
  font-weight: 700;
  border-radius: 8px;
}
.search-btn button:hover{
  transition: all 0.3s ease;
}
.search-btn i{
  font-size: 0.8rem;
  font-weight: 700;
  margin-left: 3px;
}


@media (max-width: 868px) {
  .search-details {
    display: block;
  }

  .main-search{
    left: 0;
  }
  
  .filter-icon > i{
    width: 100%;
  }
  .search-btn button{
    margin: 0px !important;
  }
  .filter-icon {
    position: absolute;
    right: 0px;
    bottom: 178px;
  }
   .icon-ar{
    left: 0px !important;
    right: unset;
    bottom:147px  ;
  }
  .category-select{
    width: 90%;
  }
  .search-filter{
    top: 21rem !important;
  }
  
}

.search-filter{
  position: absolute;
  top: 19rem;
  background-color: white;
  padding: 15px;
}