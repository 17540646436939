:root {
  --bs-primary: #0d3151;
  --bs-secondary: #b9dcc9;
  --bs-tertiary: #bedcca;
  --bs-quaternary: #7c8893;
  --alpha: #f3f4f1;
  --bs-beta: #f3f9f6;
  --light-gamma: #f4f6fa;
  --dark-alpha: #6f10a2;
  --bs-body-font-size: 0.938rem;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Cairo';
  src: url('../fonts/Cairo-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

body.en {
  font-family: 'Roboto', sans-serif;
}

body.ar {
  font-family: 'Cairo', sans-serif;
}

.bg-color-2 {
  background-color: #ffc107;
}
.body {
  font-size: 15px;
}
.w-inherit {
  width: inherit;
}
.cursor-pointer {
  cursor: pointer;
}
.border-dashed {
  border-style: dashed;
}
.bg-dark-alpha {
  background-color: var(--dark-alpha);
}
.border-light-alpha {
  border-color: var(--bs-quaternary) !important;
}
.bg-light-gamma {
  background-color: var(--light-gamma) !important;
}
.light-alpha {
  color: var(--bs-quaternary) !important;
}
.bg-tertiary {
  background-color: var(--bs-tertiary) !important;
}
.bg-light-alpha {
  background-color: var(--alpha);
}
.bg-light-beta {
  background-color: var(--bs-beta) !important;
}
.fs-7 {
  font-size: 0.75rem;
}
.fs-8 {
  font-size: 0.625rem;
}
.rtl {
  direction: rtl;
}
.ltr {
  direction: ltr;
}
.text-base {
  color: var(--bs-primary);
}

.bg-base {
  background-color: var(--bs-primary);
}

.btn-base {
  background-color: var(--bs-primary);
  color: #fff;
  border: var(--bs-primary);
}
.btn-base:hover {
  background-color: var(--bs-primary) !important;
  opacity: 0.8;
  color: #fff;
}

.btn-light {
  background-color: var(--bs-secondary);
  color: var(--bs-primary) !important;
  border: var(--bs-secondary);
}
.btn-light:hover {
  background-color: var(--bs-secondary) !important;
  color: var(--bs-primary) !important;
  opacity: 0.8;
}

.btn:disabled {
  background-color: var(--bs-primary) !important;
  opacity: 0.2;
  color: #fff;
}

.btn-purple {
  background-color: var(--dark-alpha);
  color: #fff;
}

.btn-purple:hover {
  background-color: var(--dark-alpha) !important;
  opacity: 0.8;
}

.large-checkbox {
  width: 1.1em;
  height: 1.1em;
  transform: scale(1.1);
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 2px;
  border: 1px solid lightgray;
  border-radius: 2px !important;
}

.large-radio {
  width: 1.2em;
  height: 1.2em;
  transform: scale(1.1);
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 2px;
  border: 1px solid lightgray;
}

.form-check-input,
.form-check-label {
  cursor: pointer;
}
