* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.top-section {
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  background:#0d3151;
}

.top-section .top-icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 0.7em;
}
.top-section .top-icon i {
  margin-right: 5px;
  margin-left: 15px;
  color: rgb(217, 218, 219);
}

.top-section .top-icon p {
  margin: 0;
  color: lightgray;
  font-size: 13px;
}
.social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.social-media i {
  color: rgb(217, 218, 219);
}

.social-media h6 {
  margin-top: 8px;
  font-weight: normal;
  color: lightgray;
  font-size: 13px;
}


@media (max-width: 868px) {
  .top-section {
    display: block;
    height: 60px;
  }
  
}
