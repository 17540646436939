.user-side-bar {
  height: 100vh;
  /* width: 250px; */
  background-color: #fff !important;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%); /* Hide sidebar by default */
  transition: transform 0.3s ease;
  z-index: 1050;
}

.user-side-bar.open {
  transform: translateX(0); /* Slide in sidebar when open */
}

@media (min-width: 768px) {
  .user-side-bar {
    transform: translateX(0); /* Always show sidebar on larger screens */
    position: static;
    width: auto;
    /* margin-left: 120px; */
  }
}

.toggle-btn {
  z-index: 200;
  background-color: #fff !important;
  color: black !important;
  width: 100% !important;
  height: 70px;
  border: none !important;
}
.account-menu{
  font-size: 1.5rem;
  font-weight: 200;
}

.sideBar-item {
  padding: 10px;
  color: rgb(101, 101, 101);
}

.sideBar-item.active-tab {
  background-color: #0d3151 !important;
  color: #fff !important;
}

.sideBar-item a {
  color: inherit;
}

.sideBar-item:hover {
  background-color: #0d3151;
  color: #fff;
}
