/* Footer */
.footer-main{
  padding: 0 !important;
}
.footer{
  background-image: url("../../assets/img/footer-img.jpg");
  background-size: cover;
  background-position: center;
  height: 630px;
  width: 100%;
}
.footer-img{
  margin: 50px 0;
}
.footer-img img{
  width: 60%;
}
.footer .footer-para{
  width: 93%;
  line-height: 25px;
  margin-bottom: 20px;
}
.footer .footer-para p{
  color: rgb(107, 114, 124);
}
.footer-icons a {
  color: white;
  text-decoration: none;
}
.footer-icons a > i{
  padding: 10px;
  background: rgba(128, 128, 128, 0.239);
  margin-left: 5px;
  cursor: pointer;
  border-radius: 5px;
}
.footer-icons i:hover{
  background-color: rgb(184, 219, 200);
  transition: all 0.3s ease-in-out;
}
.footer-heading-2{
  margin: 50px 0;
}
.footer-list{
  display: flex;
  flex-direction: column;
  margin-left: 0px !important;
  padding: 0px !important;
  list-style: circle;
}
.footer-list a{
  text-decoration: none;
  color: rgb(107, 114, 124);
  margin: 8px 0;
}
.footer-list a:hover{
  margin-left: 5px;
  transition: 0.3s ease;
  color: #fff;
}
.footer-input input{
  padding: 10px 20px;
  width: 90%;
}
.footer-btn button{
  background-color: rgb(184, 219, 200);
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  margin: 10px 0;
  border: none;
  outline: none;
}
.footer-btn button:hover{
  background-color: rgb(13, 49, 81);
  transition: 0.4s ease;
}
.footer-p{
  color: rgb(107, 114, 124);
}
.download-app {
  display: flex;
  gap: 10px; 
}
.footer-list-2 a i{
  color: rgb(200, 211, 222);
  font-size: 1.2rem;
  margin-right: 7px;
}
.footer-list-2 a{
  margin-bottom: 10px !important;

}
.download-app .google-img img {
  width: 150px; 
  height: 100px; 
  object-fit: contain;
}
.download-app .apple-img img {
  width: 130px; 
  height: 100px; 
  object-fit: contain;
}

/* copyright */
.copyright{
  background-color: rgb(8, 32, 57);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(182, 187, 197);
  height: 100px;
  font-size: 0.9rem;
}

@media (min-width: 375px) and (max-width: 430px) {
  .footer{
    height: 118rem;
  }
  .footer-img img{
    width: 35% !important;
  }
}