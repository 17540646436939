.payment-methods-container {
    text-align: center;
    padding: 20px;
  }
  
  .payment-methods-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .payment-method-card {
    border: 2px solid #ccc;
    padding: 20px;
    width: 200px;
    margin: 10px;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease;
  }
  
  .payment-method-card.selected {
    border-color: #007bff;
    background-color: #f0f8ff;
  }
  
  .payment-method-card img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  .payment-method-card h3 {
    font-size: 16px;
    color: #333;
  }
  
  .continue-btn {
    background-color: #0D3151;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .continue-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .continue-btn:hover:not(:disabled) {
    background-color: #0D3151;
  }
  