body {
    background-color: rgb(244, 244, 244) !important;
  }
  .sidebar {
    background-color: rgb(255, 255, 255);
    height: auto;
    width: 100%;
  }
  .sidebar-search h4 {
    font-weight: 600;
  }
  .sidebar-search input {
    width: 80%;
    height: 50px;
    padding: 10px;
    border: 1px solid rgb(231, 231, 231);
  }
  .categories-options select {
    width: 80%;
    height: 50px;
    padding: 10px;
    border: 1px solid rgb(231, 231, 231);
  }
  .range-slider .range-slider__thumb {
    width: 15px !important;
    height: 15px !important;
  }
  .range-slider .range-slider__range,
  .range-slider .range-slider__thumb {
    background: #0d3151 !important;
  }
  .range-slider .range-slider__thumb {
    border: 2px solid #fff;
  }
  .range-slider {
    width: 80%;
    height: 7px !important;
  }
  .filter i {
    border: 1px solid rgb(231, 231, 231);
    padding: 15px 18px;
    font-size: 1.2rem;
    border-radius: 8px;
  }
  .filter p {
    color: rgb(184, 219, 200);
    margin-left: 10px;
  }
  .filter i:hover {
    background-color: rgb(184, 219, 200);
    transition: all 0.3s ease;
    cursor: pointer;
    color: white;
    border: 1px solid rgb(184, 219, 200);
  }
  .filter-checkboxes {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .filter-checkboxes label {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  .filter-checkboxes input[type="checkbox"] {
    margin-right: 10px;
  }
  .custom-checkbox {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .sidebarBtn button:nth-child(1) {
    width: 60%;
    padding: 12px 0;
    margin-right: 5px;
    border: none;
    outline: none;
    background-color: #0d3151;
    color: #fff;
    border-radius: 5px;
    font-size: 0.9rem;
  }
  .sidebarBtn button:nth-child(1):hover {
    background-color: rgb(184, 219, 200);
    transition: all 0.3s ease;
  }
  .sidebarBtn button:nth-child(2) {
    width: 30%;
    padding: 10px 0;
    border: 2px solid #0d3151;
    outline: none;
    color: #0d3151;
    border-radius: 5px;
    font-size: 0.9rem;
    background-color: #fff;
  }
  .sidebarBtn button:nth-child(2):hover {
    background-color: #0d3151;
    transition: all 0.3s ease;
    color: #fff;
  }
  .hover-zoom img {
    transition: transform 0.5s ease;
    overflow: hidden;
  }
  
  .hover-zoom:hover img {
    transform: scale(1.1);
  }
  .sidebar-card {
    width: 90%;
    background-color: rgb(255, 255, 255);
    height: auto;
  }
  
  .hover-zoom {
    overflow: hidden;
  }
  
  .hover-zoom img {
    transition: transform 0.5s ease;
    width: 100%;
    border-radius: 5px;
  }
  
  .hover-zoom:hover img {
    transform: scale(1.1);
  }