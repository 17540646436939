@media screen and (max-width: 480px) {

    .main-navbar{
        border-radius: 0;
    }

    .top-section{
        display: none;
    }

    .video-background{
        display: none;
    }

    .header{
        height: 90vh;
    }

    .header-filter{
        top: 10vh;
    }

    .header-img{
        width: 112vw;
        right: 0px;
        top: 30px;
        left: 50px;
    }

    .search-filter{
        height: 30vh;
        overflow: scroll;
    }
  }