.agency-main {
    margin-left: 10px;
    background-color: #fff;
  }
  .agency-main span:nth-child(1) {
    border-radius: 20px;
    font-size: 1.5rem;
    color: #000000;
    padding: 10px;
  }
  .agency-setting-heading {
    background-color: rgb(227, 227, 227);
    width: 25%;
    height: 45px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .agency-setting-heading .agency-heading span,
  .agency-setting-heading .agency-heading p {
    font-size: 1rem;
  }
  .agency-setting-heading .agency-heading span {
    background-color: #00264d;
    color: #fff;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -18px;
  }
  .slash {
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .agency-setting-heading .agency-heading p {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .upload-btn,
  .trash-btn {
    background-color: #fff !important;
    color: rgb(145, 145, 145) !important;
    border-radius: 20px !important;
    border: none !important;
    padding: 12px 10px !important;
  }
  .upload-btn {
    position: absolute;
    top: 55%;
    right: 20%;
  }
  .upload-btn i {
    color: rgb(255, 79, 90);
  }
  .image-upload-section {
    position: relative;
  }
  .trash-btn {
    position: absolute;
    top: 55%;
    right: 5%;
  }
  .trash-btn i {
    color: rgb(255, 79, 90);
  }
  
  .upload-btn-ii {
    margin-left: 30px;
  }
  .upload-btn-ii,
  .trash-btn-ii {
    background-color: #fff !important;
    color: rgb(145, 145, 145) !important;
    border-radius: 20px !important;
    border: 1px solid rgb(235, 235, 235) !important;
    padding: 12px 10px !important;
    height: 50px !important;
    margin-top: 20px;
  }
  
  .upload-btn-ii i,
  .trash-btn-ii i {
    color: rgb(255, 79, 90);
  }
  .small{
    margin-left: 33px;
  }
  .update-store-btn{
    background-color: #00264d !important;
    border: 1px solid #00264d !important;
    font-size: 1rem !important;
    padding: 10px 20px !important;
  }
  .update-store-btn:hover{
    background-color: rgb(184, 219, 200) !important;
    border: 1px solid rgb(184, 219, 200) !important;
    transition: all 0.3s ease;
  }