.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.asset-bid-card {
  position: sticky;
  top: 110px;
}

/* Section Achievements */
.achievements-section {
  background-color: rgb(13, 49, 81);
  padding: 60px 0;
}
.card-hover:hover {
  outline: 2px solid transparent; /* Makes the outline invisible */
  outline-offset: -2px; /* Moves the outline inside the element */
  box-shadow: 0 2px 0 0 rgb(13, 49, 81); /* Adds a "bottom outline" effect */
}
.selected-card {
  outline: 2px solid transparent; /* Makes the outline invisible */
  outline-offset: -2px; /* Moves the outline inside the element */
  box-shadow: 0 2px 0 0 rgb(13, 49, 81); /* Adds a "bottom outline" effect */
}
.text-center h2 {
  color: #fff;
  font-size: 2.5rem;
  font-weight: bold;
}
.text-center p {
  color: #fff;
  font-size: 1.2rem;
}
.achievement {
  margin-bottom: 20px;
}
.achievement-circle {
  background: linear-gradient(rgb(82, 108, 130), rgb(13, 49, 81));
  width: 13vw;
  height: 13vw;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  color: #fff;
  font-size: 1.4rem;
  transition: background 0.5s ease-in-out;
}
.achievement-circle h3 {
  margin: 0;
  font-size: 42px;
  font-weight: semibold;
}
.achievement-circle p {
  margin-top: 10px;
  font-size: 1.2rem;
}
.achievement-circle:hover {
  background: linear-gradient(rgba(13, 49, 81, 1), rgba(82, 108, 130, 1));
}

/* Latest Featured Real Estate */
.featured-heading {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 150px;
}
.featured-btn button {
  border: 1px solid rgb(0, 193, 148);
  background-color: rgb(234, 247, 244);
  color: rgb(0, 193, 148);
  padding: 10px 20px;
  margin-top: 70px;
}
.featured-btn button:hover {
  background-color: rgb(0, 193, 148);
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.text-start a {
  text-decoration: none;
}

/* Choose your property in your favorite city */
.property {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
}
@media (min-width: 375px) and (max-width: 430px) {
  .responsive {
    margin-top: 15px;
  }
  .sidebar {
    padding: 0px !important;
  }
}
.property-img,
.property-img2,
.property-img3,
.property-img4,
.property-img5 {
  background-size: cover;
  background-position: center;
  height: 280px;
  width: 100%;
  transition: transform 0.3s ease;
}
.property-img {
  background-image: url("../src/assets/img/property1.webp");
}

.property-img2 {
  background-image: url("../src/assets/img/property2.jpg");
}

.property-img3 {
  background-image: url("../src/assets/img/property3.jpg");
}

.property-img4 {
  background-image: url("../src/assets/img/property4.webp");
}

.property-img5 {
  background-image: url("../src/assets/img/property5.jpg");
}

.property:hover .property-img,
.property:hover .property-img2,
.property:hover .property-img3,
.property:hover .property-img4,
.property:hover .property-img5 {
  transform: scale(1.1);
  cursor: pointer;
}

.overlay {
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.82) 100%
  );
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  transition: opacity 0.3s;
  opacity: 0;
}

.property:hover .overlay {
  opacity: 1;
}

.text-overlay {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  z-index: 1;
}

.text-overlay h4 {
  color: white;
  margin: 0;
  padding: 10px;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Real Estate */
.estate-header {
  background-image: url("assets/img/realEstateBanner.png");
  background-size: cover;
  background-position: center;
  height: 130px;
  width: 100%;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
/* aboutUs */
.aboutUs-left-side img {
  width: 95%;
}
.aboutUs-right-side {
  width: 95%;
}
.aboutUs-right-side h5 {
  color: rgb(21, 56, 87);
}
.aboutUs-right-side h5 {
  color: rgb(21, 56, 87);
}
.aboutUs-right-side p {
  color: #70778a;
  font-weight: 100;
}
.property {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.property .featured,
.property .secured {
  width: auto;
  text-align: center;
}

.property img {
  display: block;
  margin: 0 auto;
  max-width: 80px;
}

.property h5 {
  font-weight: 400;
  margin-top: 8px;
}

.property p,
.left-bottom p {
  color: #70778a;
  font-weight: 100;
  margin-top: 4px;
}
.left-bottom {
  margin-top: 80px;
  width: 90%;
}
.bottom-right img {
  width: 95%;
  margin-top: 50px;
}
.text-sm {
  font-size: 13px;
}

/* Property Details */
.property-details-body {
  background-color: rgb(244, 244, 244);
}
.badge-main {
  color: #fff;
}
.badge-main .badge1 {
  padding: 5px 8px;
  background-color: rgb(13, 49, 81);
  font-size: 0.8rem;
  border-radius: 3px;
}
.badge-main .badge2 {
  padding: 5px 8px;
  background-color: rgb(220, 53, 69);
  font-size: 0.8rem;
  border-radius: 3px;
}
.property-info {
  color: #878c9f;
}
.property-info span {
  font-weight: 100;
  font-size: 0.9rem;
}

.live-dot {
  background-color: green;
  border-radius: 50%;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.3);
    opacity: 0.7;
  }
}

/* Carousel */
.main-carousel img {
  height: 500px;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.3s ease; /* Add transition for smooth zoom effect */
}

.main-carousel img:hover {
  transform: scale(1.2); /* Apply zoom effect */
}
.thumbnail-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}
.thumbnail {
  width: 150px;
  height: 80px;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.3s ease, filter 0.3s ease;
  filter: grayscale(100%);
  flex: 0 1 calc(10% - 10px);
}
.thumbnail.selected {
  filter: grayscale(0%);
}

.preview-imgs {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 10px;
}
.preview-img {
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.3s ease, filter 0.3s ease;
  flex: 0 1 calc(10% - 10px);
}

.more-info-card {
  background-color: #fff;
  height: auto;
}
.this_list {
  font-weight: 200;
}
.more-info-card h2 {
  font-weight: 300;
  font-size: 28px;
  margin-bottom: 10px;
}
h3.text-right {
  font-weight: 200;
}
.info-tab1 i {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(232, 233, 241);
  border-radius: 5px;
  color: rgb(13, 49, 81);
  font-size: 1.3rem;
}
.info-tab1 i:hover {
  border: 1px solid rgb(184, 219, 200);
  background-color: rgb(184, 219, 200);
  color: #fff;
  transition: all 0.3s ease;
  cursor: pointer;
}

.info-tab1 span {
  color: #70778a;
}

.info-tab1 .id-num {
  margin: 0 10px;
}

.info-tab1 .id-num p {
  margin-bottom: 3px !important;
  font-size: 14px;
}

.info-tab1 .id-num span {
  font-weight: 100;
  font-size: 14px;
}
.col-6.col-md-3 {
  display: grid;
  grid-template-columns: 30% 70%;
}
.more-info-card {
  padding: 40px;
}

@media (min-width: 768px) {
  .info-tab1 .id-num {
    margin-left: 20px;
  }
  .info-tab1 .infoTab {
    margin-left: 80px;
  }
}

/* // Mobile Css */
@media (max-width: 868px) {
  .col-6.col-md-3 {
    grid-template-columns: 40% 60% !important;
  }
  .more-info-card {
    padding: 20px !important;
  }
  .sticky-map {
    position: relative !important;
    top: 90px;
  }
}

.hr {
  color: gray !important;
}
.icon-col {
  color: #003366 !important;
}

.mb-3 {
  margin-bottom: 1rem;
}
.check-txt {
  font-weight: 100;
}
.left-text {
  font-weight: 100;
  margin: 30px 0;
}
.left-txt {
  font-weight: 100;
}
.star-icon i {
  color: rgb(255, 184, 14);
}
.details-btn {
  background-color: #003366 !important;
  border: 1px solid #003366 !important;
  padding: 10px 25px !important;
  font-weight: 200 !important;
}
.details-btn:hover {
  background-color: rgb(184, 219, 200) !important;
  border: 1px solid rgb(184, 219, 200) !important;
  transition: all 0.4s ease !important;
}
/* property-details Right */
.card {
  margin-top: 45px;
  border: none !important;
}
.card-body img {
  margin-right: 15px;
  width: 70px;
  height: 70px;
  border: 1px solid gray;
  margin-top: 30px;
}
.card-body h5 {
  margin: 0;
}
button {
  background-color: #00264d;
}
.actoins-icons i {
  padding: 10px;
  font-size: 1.2rem;
  background-color: #fff;
  color: #00264d;
  border: 1px solid rgb(240, 242, 247);
}
.actoins-icons i:hover {
  background-color: rgb(184, 219, 200);
  color: #00264d;
  border: 1px solid rgb(240, 242, 247);
  color: #fff;
  transition: all 0.3s ease;
  cursor: pointer;
}
.right-form h3:nth-child(1) {
  font-weight: 100;
  margin: 10px 0;
}
.form-group input,
.form-group textarea {
  font-weight: 100;
}
.form-group input {
  padding: 15px 10px;
  margin: 10px 0;
}
.sendButton {
  width: 100%;
  color: #fff;
  height: 50px;
  font-weight: 400;
  font-size: 0.9rem;
  margin-top: 10px;
  border: 0;
  outline: none;
}
.sendButton:hover {
  transition: all 0.3s ease;
  background-color: rgb(184, 219, 200);
}
.second-right {
  background-color: #fff;
  margin-top: 110px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.second-right h3 {
  font-weight: 300;
  margin-left: 15px;
}

.second-right img {
  width: 90%;
  height: 220px;
  display: block;
  margin: 0 auto;
}
.details-props {
  margin-left: 15px;
}
.details-props p {
  margin-top: 20px;
  color: #878c9f;
  font-weight: 100;
}
.third-right {
  background-color: #fff;
}

/* Login Form */
.loginForm {
  margin-top: 100px;
}
.form-width {
  min-width: 550px;
  max-width: 850px;
}
Form.Control {
  color: #878c9f;
}
.form-input {
  padding: 12px 5px !important;
  outline: none;
}
.fw-light a {
  text-decoration: none;
}
.otpBtn {
  background-color: rgb(13, 49, 81) !important;
  border: 1px solid rgb(13, 49, 81) !important;
  padding: 10px 15px !important;
  font-weight: 200 !important;
}
.otpBtn:hover {
  background-color: rgb(184, 219, 200) !important;
  transition: all 0.3s ease !important;
  border: 1px solid rgb(184, 219, 200) !important;
}
.otpBtn2 {
  background-color: rgb(97, 121, 142) !important;
  border: 1px solid rgb(97, 121, 142) !important;
  padding: 10px 15px !important;
  font-weight: 200 !important;
}
.otpBtn2:hover {
  background-color: rgb(184, 219, 200) !important;
  border: 1px solid rgb(184, 219, 200) !important;
  transition: all 0.3s ease;
}
.formCheck {
  font-size: 0.7rem;
}
.form-btn {
  background-color: rgb(13, 49, 81) !important;
  border: 1px solid rgb(13, 49, 81) !important;
  padding: 10px 25px !important;
  font-weight: 200 !important;
}
/* Carasoul */
.slider-card-footer h4 {
  font-size: 1rem;
  font-weight: 200;
}

/* Packages */
.package1 .package-head {
  background-color: rgb(13, 49, 81);
  color: #fff;
  padding: 50px 20px;
  height: 220px;
}
.package1 .package-head h2 {
  font-weight: 100;
  font-size: 2rem;
}
.package1 .package-head > h1 > sub {
  font-size: 1.2rem;
}
.package-body {
  border: 1px solid rgb(218, 218, 218);
  padding: 20px 0;
}
.package-body p {
  color: rgb(91, 91, 91);
  font-size: 1rem;
  line-height: 30px;
  margin: 0 30px;
}
.package-btn {
  padding: 20px 0;
}
.package-btn a {
  text-decoration: none;
  color: rgb(13, 49, 81);
  padding: 10px 20px;
  background-color: transparent !important;
  border: 1px solid rgb(13, 49, 81);
}
.package-btn a:hover {
  background-color: rgb(13, 49, 81) !important;
  transition: all 0.4s ease !important;
  color: #fff !important;
}
.package-footer {
  background-image: url("assets/img/packeges_img.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 260px;
  display: flex;
  align-items: center;
}
.package-footer h2,
.package-footer p {
  margin: 0;
}
.package-footer-btn button {
  background-color: rgb(0, 193, 148);
  font-weight: 100;
  border: none;
  color: white;
  padding: 10px 35px;
  border-radius: 5px;
  cursor: pointer;
}
.package-footer-btn button:hover {
  background-color: rgb(0, 163, 118);
  transition: all 0.3s ease;
}

@media (max-width: 868px) {
  .achievement-circle {
    width: 35vw;
    height: 35vw;
  }
  .form-width {
    min-width: 350px;
  }
}

/* Registration */
.react-tel-input .form-control {
  width: 100% !important;
  height: 45px !important;
  border: 1px solid rgb(238, 241, 243) !important;
}

.ad-navtab {
  width: 100%;
  border: 1px solid #00264d !important;
  border-radius: 0px !important;
}

.sidebar {
  background-color: rgb(255, 255, 255);
  height: auto;
  width: 90%;
}
.sidebar-search h4 {
  font-weight: 600;
}
.sidebar-search input {
  width: 80%;
  height: 50px;
  padding: 10px;
  border: 1px solid rgb(231, 231, 231);
}
.categories-options select {
  width: 80%;
  height: 50px;
  padding: 10px;
  border: 1px solid rgb(231, 231, 231);
}
.range-slider .range-slider__thumb {
  width: 15px !important;
  height: 15px !important;
}
.range-slider .range-slider__range,
.range-slider .range-slider__thumb {
  background: #0d3151 !important;
}
.range-slider .range-slider__thumb {
  border: 2px solid #fff;
}
.range-slider {
  width: 80%;
  height: 7px !important;
}
.filter i {
  border: 1px solid rgb(231, 231, 231);
  padding: 15px 18px;
  font-size: 1.2rem;
  border-radius: 8px;
}
.filter p {
  color: rgb(184, 219, 200);
  margin-left: 10px;
}
.filter i:hover {
  background-color: rgb(184, 219, 200);
  transition: all 0.3s ease;
  cursor: pointer;
  color: white;
  border: 1px solid rgb(184, 219, 200);
}
.filter-checkboxes {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.filter-checkboxes label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.filter-checkboxes input[type="checkbox"] {
  margin-right: 10px;
}
.custom-checkbox {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.sidebarBtn button:nth-child(1) {
  width: 60%;
  padding: 12px 0;
  margin-right: 5px;
  border: none;
  outline: none;
  background-color: #0d3151;
  color: #fff;
  border-radius: 5px;
  font-size: 0.9rem;
}
.sidebarBtn button:nth-child(1):hover {
  background-color: rgb(184, 219, 200);
  transition: all 0.3s ease;
}
.sidebarBtn button:nth-child(2) {
  width: 30%;
  padding: 10px 0;
  border: 2px solid #0d3151;
  outline: none;
  color: #0d3151;
  border-radius: 5px;
  font-size: 0.9rem;
  background-color: #fff;
}
.sidebarBtn button:nth-child(2):hover {
  background-color: #0d3151;
  transition: all 0.3s ease;
  color: #fff;
}
.hover-zoom img {
  transition: transform 0.5s ease;
  overflow: hidden;
}

.hover-zoom:hover img {
  transform: scale(1.1);
}
.sidebar-card {
  width: 90%;
  background-color: rgb(255, 255, 255);
  height: auto;
}

.hover-zoom {
  overflow: hidden;
}

.hover-zoom img {
  transition: transform 0.5s ease;
  width: 100%;
  border-radius: 5px;
}

.hover-zoom:hover img {
  transform: scale(1.1);
}

/* Rightbar */
.heading {
  display: flex;
  justify-content: space-between;
}
.property-card {
  width: 100%;
  max-width: 400px;
  position: relative;
  height: 90%;
}

.badge-sale {
  position: absolute;
  top: 10px;
  left: 10px;
}

.badge-new {
  position: absolute;
  top: 10px;
  right: 80px;
}

.badge-top {
  position: absolute;
  top: 10px;
  right: 10px;
}

.hover-icons {
  display: none;
  position: absolute;
  bottom: 10px;
  right: 10px;
  gap: 10px;
  z-index: 1000;
  cursor: pointer;
}

.price-overlay {
  position: absolute;
  bottom: 40px;
  left: 10px;
  font-weight: bold;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
}

.property-card:hover .hover-icons {
  display: flex;
}
.property-card .carousel-item img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
.slider-footer {
  display: flex;
  justify-content: space-between;
}
.slider-card-footer img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.slider-card-footer button {
  border: none;
  outline: none;
  background-color: transparent;
  color: black;
  border-radius: 5px;
  padding: 10px 15px;
}
.slider-card-footer button:hover {
  background-color: #0d3151;
  color: #fff;
  transition: all 0.5s ease;
}

/* Auctions */
.auction-img {
  width: 70px;
  height: 70px;
  border-radius: 5px;
  object-fit: cover;
}
.background-color {
  background-color: rgb(244, 244, 244);
}
.form-main-head {
  width: 100%;
  height: auto;
  background-color: #fff;
}
.form-main-head h2 {
  font-weight: 300;
}
/* table {
  border: 1px solid gray !important;
} */
table thead tr th {
  color: rgb(104, 104, 104) !important;
  background-color: rgb(248, 248, 248) !important;
}
table tbody tr td img {
  width: 50px !important;
}

/* Auction Details */
.auctions-details h1 {
  margin-left: 30px;
  font-size: 2rem;
  margin-bottom: 30px;
  font-weight: 300;
}
.img-detailed-info h3 {
  font-weight: 700;
  word-spacing: 3px;
  letter-spacing: 1px;
}
.carousel-img {
  width: 95% !important;
  height: 500px;
}
.carousel-img img {
  width: 100%;
  aspect-ratio: 1/1;
}
.thumbnail-img {
  width: 80% !important;
}
.thumbnail-img .img-thumbnail {
  width: 100%;
  aspect-ratio: 16/10;
}
.countdown-container {
  text-align: center;
  font-weight: 500;
  font-size: 1.5rem;
}

.countdown-headings,
.countdown-values {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0.5rem;
}

.countdown-headings span,
.countdown-values span {
  flex: 1;
  text-align: center;
}

.auction-details-btn {
  background-color: #00264d !important;
  border: 1px solid #00264d !important;
  font-weight: 200 !important;
}
.bid-btn {
  background-color: #00264d !important;
  border: 1px solid #00264d !important;
  width: 33%;
  margin-left: 20px !important;
}
.bid-buy-btn {
  background-color: #003366 !important;
  border: 1px solid #003366 !important;
  padding: 10px 25px !important;
}

.sticky-map {
  position: fixed;
  top: 90px;
  width: 41% !important;
}

/* Dashboard */
.dashboard {
  background-color: #fff;
  width: 97.7%;
  margin-left: 10px;
}
.dashboard-head-img {
  width: 12%;
  border-radius: 50%;
}
.dashboard-head-img img {
  width: 100%;
  border-radius: 50%;
}
.sideBar-item.active-tab {
  border-bottom: 1px solid rgb(184, 219, 200);
  color: #fff !important;
}
.sideBar-item a:hover,
.sideBar-item a:focus {
  color: #fff !important;
}
.sec-ii-icons .icon-i {
  background-color: #fff;
  padding: 8px;
  width: 23%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 7.9px;
  min-height: 150px;
}
.sec-ii-icons .icon-i i {
  background-color: rgb(207, 235, 245);
  color: rgb(0, 153, 204);
  font-size: 2rem !important;
  padding: 16px 25px !important;
  border-radius: 50% !important;
}
.icon-ii i {
  background-color: rgb(219, 246, 230) !important;
  color: rgb(30, 142, 77) !important;
}
.icon-iii i {
  background-color: rgb(255, 239, 219) !important;
  color: rgb(255, 136, 0) !important;
}
.icon-iv i {
  background-color: rgb(255, 243, 244) !important;
  color: rgb(255, 60, 72) !important;
}
.icon-i h5,
.icon-i span {
  font-size: 0.95rem;
}
.card-main {
  margin-left: 10px;
}
table .thead tr {
  background-color: rgb(228, 240, 253) !important;
}
table .thead tr th {
  font-weight: 400;
}
table .tbody tr td {
  font-weight: 100;
}
@media (max-width: 576px) {
  .sec-ii-icons .icon-i {
    padding: 10px;
    width: 90%;
    height: auto;
    margin: 5px 0;
  }

  .sec-ii-icons .icon-i i {
    font-size: 1.5rem;
    padding: 10px 15px;
  }
}

/* Real Estate Dashboard */
.search-bar {
  position: relative;
}
.search-bar input {
  width: 100px;
}
.custom-width {
  width: 50% !important;
  padding: 10px !important;
  border-radius: 20px !important;
  background-color: rgb(246, 246, 246) !important;
}
.estate-search-bar {
  background-color: #fff;
}
.estate-search {
  position: absolute;
  left: 45%;
  top: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.estate-btn {
  background-color: #00264d !important;
  border: 1px solid #00264d !important;
  font-size: 0.8rem !important;
  width: 20%;
  height: 40px;
  border-radius: 30px !important;
  font-weight: 300 !important;
  margin-right: 20px;
}
.estate-btn:hover {
  color: #fff;
  background-color: rgb(184, 219, 200) !important;
  transition: all 0.3s ease;
  border: 1px solid rgb(184, 219, 200) !important;
}
.badge-publish {
  background-color: rgb(228, 248, 221);
  color: rgb(92, 180, 62);
  padding: 5px 10px;
  font-size: 0.8rem;
  border-radius: 10px;
}
.estate-table {
  margin-left: 10px;
  padding: 20px !important;
  background-color: #fff !important;
  width: 100%;
  border: 1px solid rgb(222, 222, 222) !important;
}
.estate-table thead tr th {
  font-weight: 300 !important;
  border-right: 1px solid rgb(222, 222, 222);
}
.estate-table thead tr th:last-child {
  border-right: none;
}
.estate-table tbody tr {
  height: 100px;
}
.estate-table tbody tr td {
  text-align: center;
  vertical-align: middle;
  border-right: 1px solid rgb(222, 222, 222); /* Add right border to each cell */
}
.estate-table tbody tr td:last-child {
  border-right: none; /* Remove the right border for the last cell in each row */
}

.menu-icons {
  margin-right: 5px;
}
.estate-menu #dropdown-basic-button {
  background-color: #fff;
  color: rgb(100, 100, 100);
  font-size: 1.2rem;
  border: none;
}
.dropdown-toggle::after {
  display: none !important;
}

.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(
    255,
    255,
    255,
    0.7
  ); /* Optional semi-transparent background */
  z-index: 999;
}

.hover-shadow-lg:hover {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.feedback-radios > input {
  margin: 0;
  padding: 0;
}

/* Add rounded corners to the table */
.rounded-table {
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 5px; /* Adjust the radius as needed */
  overflow: hidden; /* Ensures the corners are rounded */
}

/* Add horizontal borders between rows */
.rounded-table tbody tr {
  border-bottom: 1px solid #dee2e6; /* Bootstrap's default border color */
}

/* Optional: Add padding and alignment for better appearance */
.rounded-table th,
.rounded-table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: none; /* Remove default top border */
}

.remove-last-border-bottom *:last-child {
  border-bottom: none; /* Removes border from the last element */
}

.dropdown-blue-btn > button {
  background-color: #0d3151;
}

div.rounded-remove {
  border-radius: 0 !important;
}

.no-underline {
  text-decoration: none !important;
}