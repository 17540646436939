.fontHover .FaMapMarkerAlt {
  color: rgb(0, 184, 148);
  background-color: whitesmoke;
  border-radius: 50px;
  padding: 11px;
}

.fontHover .FaMapMarkerAlt:hover {
  background-color: rgb(0, 184, 148) !important;
  color: white;
}

.fontHover .FaPhoneAlt {
  color: rgb(0, 184, 148);
  background-color: whitesmoke;
  border-radius: 50px;
  padding: 11px;
}

.fontHover .FaPhoneAlt:hover {
  background-color: rgb(0, 184, 148) !important;
  color: white;
}
.fontHover .IoShareSocialOutline {
  color: rgb(0, 184, 148);
  background-color: whitesmoke;
  border-radius: 50px;
  padding: 11px;
}

.fontHover .IoShareSocialOutline:hover {
  background-color: rgb(0, 184, 148) !important;
  color: white;
}


.FaMapMarkerAlt {
  font-size: 56px;
  position: relative;
  top: 11px;
  background: whitesmoke;
  border-radius: 50px;
  padding: 11px;
}
.IoShareSocialOutline  {
  font-size: 56px;
  position: relative;
  top: 11px;
  background: whitesmoke;
  border-radius: 50px;
  padding: 11px;
}

.FaPhoneAlt {
  font-size: 56px;
  position: relative;
  top: 11px;
  background: whitesmoke;
  border-radius: 50px;
  padding: 11px;
}


.CardOne {
  border: 1px solid #ddd5d5 !important;
  color: #A7A7A7 !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-weight: bold;
}
.secondCard {
  border: 1px solid #ddd5d5 !important;
  color: #A7A7A7 !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-weight: bold;
}

.btn-color {
  background-color: #0D3151 !important;
  width: 100%;
  border-radius: 3px;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  opacity: 1;
  height: 50px;
  border-radius: 0%;
}
.form-control{
  background-color: #f9fbfe !important;
}
.fontIcons a{
  color: #A7A7A7;
}



.custom-tabs .nav-link {
  color: green;
  border: 1px solid green;
  background-color: white;
  border-radius: 5px;
  margin-right: 5px;
  transition: all 0.3s ease-in-out;
}

.custom-tabs .nav-link.active {
  color: white;
  background-color: green;
  border-color: green;
}

