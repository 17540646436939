.main-navbar {
  height: 86px;
  width: 100%;
  z-index: 100;
}
.sticky{
  position: fixed !important;
  top: 0;
}
.navbar-nav .nav-link {
  font-size: 15px; 
  padding:10px 20px;
  color: #000;
  position: relative;
  margin-left: 8px;
}
.navbar-nav .nav-link:hover::after {
  content: '';
  display: block;
  height: 3px;
  /* background-color: rgb(13, 49, 81); */
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -25px;
  transition: all 0.5s ease;
}
.bi-person-circle {
  color: #000;
}

.registerBtn1{
  border: 1px solid rgb(184, 219, 200);
}
/* .registerBtn button:hover{
  color: #fff;
  transition: all 0.3s ease;
  background-color: rgb(184, 219, 200) !important;
} */
.navbar-collapse{
  background-color: white;
}

@media  (max-width: 780px) {
  .navbar-collapse{
    position: absolute;
    left: 0px;
    right: 0px;
    top: 88px;
    padding-bottom:20px ;
    
  }
}
/* Target the dropdown toggle on hover and hide the default caret */
#dropdown-download.nav-link:hover::after {
  display: none !important;
}
